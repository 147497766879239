import React from "react"

// import bg1Img from "../../images/qbo/bg1.svg"
import qboImg from "../../images/qbo/qbo.svg"
import bg2 from "../../images/qbo/bg2.svg"
import cqaImg from "../../images/qbo/cqa.png"
import closeImg from "../../images/qbo/close.svg"
import LucaImg from "../../images/qbo/LucaPlus.png"
const getStartUrl = "/signup/"
export default function Hero({toggleVideoModal}) {
  const goPricingPage = e => {
    e.preventDefault()
    try {
      document.querySelector('.new-pricing__hero').scrollIntoView({
        behavior: 'smooth',
        top: 0,
        left: 0,
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }
  return (
    <div className="qbo__hero">
      <div className="hero-inner container flex justify-between items-center sm:items-start mx-auto">
        <div className="left text-center sm:text-left mt-0 sm:mt-6">
          <div className="title">
            Quick and Easy Guide to Start e-Invoicing with Intuit QuickBooks
          </div>
          <div className="desc mt-6">
          Existing Quickbooks users can integrate LUCA Plus to start e-invoicing
           QuickBooks
          </div>
          <div className="flex justify-center sm:justify-start items-center mt-8">
            <button
              className="signup-btn focus:outline-none"
              onClick={goPricingPage}
            >
              Select Plan
            </button>
          </div>
          <div className="flex mt-6 logo items-center">
            <img src={qboImg} alt="qbo" className="image mr-5" />
            <img src={closeImg} alt="closeImg" className="cross-img mr-5"/>
            <img src={LucaImg} alt="qbo" className="image lucaplus-img" />
          </div>
        </div>
        <div className="right flex sm:flex mt-12">
          <div className="fvideo cursor-pointer mx-auto  w-11/12 xl:w-full" onClick={toggleVideoModal}>
            <div className="fvideo__play" />
            <img src={bg2} alt="video" />
          </div>
        </div>
      </div>
    </div>
  )
}
