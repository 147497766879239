import React, { useLayoutEffect,useState } from "react"

import SEO from "../components/seo"
import ReactGA from "react-ga"
import NewFooter from "../components/pricing/footer"
import "../scss/style.scss"

import Top from "../components/qbo/top"
import Hero from "../components/qbo/hero"
import Partner from "../components/qbo/partner"
import Plans from "../components/myob/plan";
import Faq from "../components/qbo/faq"
import Fans from "../components/new-landing/fans"
import Stories from "../components/qbo/stories"
import Feats from "../components/qbo/feats"
import About from "../components/new-landing/about"
import Modali, { useModali } from "../components/modal"
import Fuction from "../components/qbo/function"
import Awards from "../components/qbo/awards"
import specialOfferBtnImg from "../images/qbo/specialOfferBtnImg.svg";
import Trusts2 from "../components/myob/trusts2"


export default function QBO() {
  const [registrationDetails, setRegistrationDetails] = useState(null);

  const [videoModal, toggleVideoModal] = useModali()
  const [heroVideoModal, toggleHeroVideoModal] = useModali()
  const navItemClick = (navItem) => {
    ReactGA.event({
      category: "NAV_ITEM",
      action: `Clicked ${navItem} Nav Item of qbo Page`,
    })
  }
  const clickOfferBtn = () => {
    ReactGA.event({
      category: 'Qbo_30 day_Widget',
      action: 'Qbo_30 day_Widget clicked'
    });
    gotoPricing();
  }

  const gotoPricing = () => {
    setRegistrationDetails({isPromoCode: true})
    try {
      document.querySelector('.new-pricing__hero').scrollIntoView({
        behavior: 'smooth',
        top: 0,
        left: 0,
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }

  const topBannerClick = () => {
    ReactGA.event({
      category: 'Qbo page_30 day_Banner',
      action: 'Qbo page_30 day_Banner'
    });
    gotoPricing();
  }

  return (
    <div className="h-full font-robo qbo">
      <SEO title="QuickBooks E-invoicing | LUCA+ Australia | Also connects XERO & MYOB" einvoice={true} description="Use LUCA+ QuickBooks e-invoicing platform to automatically populate invoices directly into your accounting system."
      keywords="Quickbooks e-invoicing" />
      <Top navItemClick={navItemClick}/>
      <div className="footer-banner">
        <div className="text pr-3">
          Try LUCA Plus FREE for 30 days
        </div>
        <div className="flex">
          <div className="btn font-medium text2" onClick={topBannerClick}>
            Get Started
          </div>
        </div>
      </div>
      <Hero toggleVideoModal={toggleHeroVideoModal} />
      <div className="pt-16 sm:pt-16">
        <div onClick={clickOfferBtn}>
          <img src={specialOfferBtnImg} className="mx-auto cursor-pointer" />
        </div>
      </div>
      <Plans page="qbo" registrationDetails={registrationDetails} />
      <Faq toggleVideoModal={toggleVideoModal} />
      <Fans myob={false}/>
      <Feats />
      <About />
      <Awards />
      <Fuction />
      <Trusts2 />
      <NewFooter page='QBO page' simple={true}/>
      <Modali.Modal {...videoModal}>
        <div className="hero-video">
          <iframe
            src="https://www.youtube.com/embed/Z98YdeLZcrE?autoplay=1&enable_js=1"
            title="Introduction to LUCA+"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
            allow="autoplay"
          />
        </div>
      </Modali.Modal>
      <Modali.Modal {...heroVideoModal}>
        <div className="hero-video">
          <iframe
            src="https://www.youtube.com/embed/Z98YdeLZcrE"
            title="QBO page"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
            allow="autoplay"
          />
        </div>
      </Modali.Modal>
    </div>
  )
}
