import React from "react"
import logoImage from "../../images/new-landing/logo.svg"
import { meetingUrl } from "../../constants/url"

export default function Top({partner = false, toggleVideoModal, navItemClick}) {
  return (
    <div className="top">
    <div className="container">
      <div className="flex items-center justify-between items-center mx-auto">
        <div className="top-header logo flex items-center sm:justify-start justify-content-center">
         <a href="/" className="logo ml-0 sm:mx-0 flex items-center">
          <img src={logoImage} alt="logo" className="lucaplus-img pr-3"  />
          {/* <span className="w-1/2 hidden" /> */}
          <div className="whitespace-no-wrap ml-3 title hidden sm:block items-center">
            Simpler and Faster Business
          </div>
          </a>
          </div>
        <div className="flex sm:flex items-center">
          <a
            onClick={navItemClick}
            className="contact-btn focus:outline-none flex justify-center items-center mr-5"
            href="https://meetings.hubspot.com/ray-wang/meeting-with-luca-plus"
          >
            Contact us
          </a>
        </div>
      </div>
    </div>
  </div>
  )
}
