import React from "react"
import c1 from "../../images/qbo/feats/c1.svg"
import c2 from "../../images/qbo/feats/c2.svg"
import c3 from "../../images/qbo/feats/c3.svg"
import s1 from "../../images/qbo/feats/c4.svg"
import s2 from "../../images/qbo/feats/c5.png"
import s3 from "../../images/qbo/feats/c6.svg"

export default function Feats() {
  return (
    <div className="container feats pb-32 sm:mt-6">
      <div className="title">
      Integrate LUCA Plus with your QuickBooks account today
      </div>
      <div className="w-4/5 mx-auto text-center">
        <div className="mt-8 sm:mt-16 feats-icons flex sm:flex-row flex-col justify-around items-center">
          <div className="one ">
            <img src={c1} alt="c1" className="mx-auto" />
            <div className="mt-6 sm:mt-8 feats-info">
              Eliminate Invoice Errors
            </div>
            <div className="divide "></div>
          </div>
          <div className="one mt-6 sm:mt-auto">
            <img src={c2} alt="c2" className="mx-auto" />
            <div className="mt-6 sm:mt-8 feats-info">Get Paid 32% Faster</div>
            <div className="divide"></div>
          </div>
          <div className="one mt-6 sm:mt-auto">
            <img src={c3} alt="c3" className="mx-auto" />
            <div className="mt-6 sm:mt-8 feats-info">Avoid Invoice Fraud</div>
          </div>
        </div>
      </div>
      https://www.lucaplus.com/sign-up/?plan=combined&lucapay=true&__hstc=224838149.b1dc40d135f2ae79dc2a95531d9425b9.1683523440806.1683606906215.1683868086752.3&__hssc=224838149.2.1683868086752&__hsfp=2550508550
      <div className="mt-8 sm:mt-20 w-4/5 mx-auto text-center">
        <div className="mt-8 sm:mt-16 feats-icons flex sm:flex-row flex-col justify-around items-center">
          <div className="one ">
            <img src={s1} alt="s1" className="mx-auto" />
            <div className="mt-6 sm:mt-8 feats-info">
              Know When You’re Getting Paid
            </div>
            <div className="divide "></div>
          </div>
          <div className="one mt-6 sm:mt-auto">
            <img src={s2} alt="s2" className="mx-auto" />
            <div className="mt-6 sm:mt-8 feats-info">
              Automate Your Bookkeeping
            </div>
            <div className="divide"></div>
          </div>
          <div className="one mt-6 sm:mt-auto">
            <img src={s3} alt="s3" className="mx-auto" />
            <div className="mt-6 sm:mt-8 feats-info">
              Save Up to 10 Hours Per Week
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
