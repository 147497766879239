import React, { useState } from "react"
import videoImge from "../../images/qbo/video.png"
import QaItem from "../new-landing/qa-item"
export default function Faq({ toggleVideoModal }) {
  const [opens, setOpens] = useState([false, false, false, false])

  return (
    <section className="faq">
      <div className="container flex flex-col  sm:flex-row justify-center items-center gap-20">
        <div className="text-left w-full sm:w-auto">
          <h2 className="desc sm:mt-0 mt-8 sm:text-left sm:font-bold sm:text-left text-center">
          E-invoicing FAQs
          </h2>
          <ul className="faq__list leading-5 text-neutral-3 font-medium">
            {faqs.map(({ title, content }, index) => (
              <QaItem
                title={title}
                content={content}
                key={`item-${index}`}
                index={index}
                setOpens={setOpens}
                opens={opens}
              />
            ))}
          </ul>
        </div>
        <div className="fvideo cursor-pointer hidden sm:block" onClick={toggleVideoModal}>
          <span className="overlay" />
          <div className="fvideo__play" />
          <img src={videoImge} alt="video"  />
        </div>
      </div>
    </section>
  )
}

const faqs = [
  {
    title: "Who is behind LUCA Plus?",
    content:
      <div><div className='mb-2'>LUCA Plus is an Australian company based in Melbourne. </div><div className='mb-2'> We are accredited by the PEPPOL and the Australian Taxation Office (ATO). </div> We are a team of accountants and technologists who are passionate about e-invoicing. There are already 1,000 other local Australian SME’s on the LUCA Plus network, and we have received many accolades: <br/>-	2019 ‘Accountancy Software of the Year’ Finalist<br/>-	Member of Melbourne Accelerator (Australia’s leading start up program)<br/>-	2020 Global Finalist for the Jumpstarter Pitch Competition </div>,
  },
  {
    title: "What is e-invoicing? Why is it important?",
    content:
      <div><div className='mb-2'>From the ATO website: “Paper and email based invoicing is manually intensive and prone to human error. Electronic invoicing (or e-invoicing) is the direct, electronic exchange of invoices between suppliers’ and buyers’ financial systems.” </div><div className='mb-2'> So e-invoicing is NOT just an email  or PDF invoice. Imagine your accounting system can ‘talk to’ the accounting system of your clients and suppliers. Now imagine those communications are automatic and encrypted, and you’re getting close to understanding e-invoicing. It’s a payments & billing revolution!</div> It’s important because it saves your businesses time and money. According to estimates by Deloitte it could result in benefits to the Australian economy of $28 billion over ten years.</div>,
  },

  {
    title: "What does LUCA Plus do that my accounting software can't?",
    content:
      <div>LUCA Plus creates a ‘live’ link between your accounting software and that of your clients and suppliers. It essentially creates a new ‘network’ which functions as an automated communication channel between payer and payee. This means you can better track progress of payments owed to you, and that your invoices get paid much faster. Which is a big help for managing your cash-flow!</div>,
  },

  {
    title: "What data does LUCA Plus see when I connect QuickBooks?",
    content:
        <div>LUCA Plus only uses the payments and invoicing data already in your accounting platform. It does not, for instance, access balance sheet, or profit and loss data. By syncing your accounts receivable and accounts payable data, LUCA Plus is able to create the ‘live’ encrypted data link between you and your debtors and creditors. It’s what creates all the magic of the LUCA Plus system.  <br/>(It’s important to note that LUCA Plus is an authorised partner QuickBooks, and that such technology partners must undergo the highest level of scrutiny).</div>,
  },
]
